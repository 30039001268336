<template>
  <div>
    <course-list-add-new
      :is-add-new-course-sidebar-active.sync="isAddNewCourseSidebarActive"
      :visibility-options="visibilityOptions"
      :status-options="statusOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-between mb-1 mb-md-0">
            <div>
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </div>

            <div style="flex-basis: 50%;">
              <!-- Filters -->
              <course-list-filters
                :visibility-filter.sync="visibilityFilter"
                :status-filter.sync="statusFilter"
                :visibility-options="visibilityOptions"
                :status-options="statusOptions"
              />
            </div>

          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="isAddNewCourseSidebarActive = true" v-if="false">
                <span class="text-nowrap">Add Course</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        :items="fetchCourses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Course -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'apps-roster-course-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">{{ (data.item.classes || []).length }} total classes</small>
        </template>

        <!-- Column: Instructor -->
        <template #cell(instructor)="data">
          <b-media vertical-align="center" v-if="data.item.instructor && data.item.instructor.id">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.instructor.avatar"
                :text="avatarText(data.item.instructor.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.instructor.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.instructor.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.instructor.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.instructor.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.instructor.username }}</small>
          </b-media>
          <span v-else>No Instructor Assigned</span>
        </template>
        <!-- Column: Live Courses -->
        <template #cell(live_classes)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.classes.filter((clazz) => clazz.status === 'live').length
            }}</span>
          </div>
        </template>
        <!-- Column: Complete Courses -->
        <template #cell(completed_classes)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.classes.filter((clazz) => clazz.status === 'completed').length
            }}</span>
          </div>
        </template>
        <!-- Column: Complete Courses -->
        <template #cell(profiles)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.profiles.length }}</span>
          </div>
        </template>
        <!-- Column: Dates -->
        <template #cell(dates)="data">
          <div class="text-nowrap">
            <span v-if="data.item.classes && data.item.classes.length > 0" class="align-text-top text-capitalize">
              {{ classesToRange(data.item.classes.map((cl) => cl.date)) }}
            </span>

            <span v-else>No Classes Assigned</span>
          </div>
        </template>

        <!-- Column: Locations -->
        <template #cell(locations)="data">
          <div class="text-nowrap">
            <span v-if="data.item.classes && data.item.classes.length > 0" class="align-text-top text-capitalize">{{
              data.item.classes
                .map((clazz) => clazz.location)
                .filter((item, i, ar) => ar.indexOf(item) === i)
                .join(', ')
            }}</span>
            <span v-else>No Classes Assigned</span>
          </div>
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge pill :variant="`light-${resolveCourseVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveCourseStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item :to="{ name: 'apps-roster-course-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-roster-course-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="archiveCourse(data.item.id)">
              <feather-icon icon="ArchiveIcon" />
              <span class="align-middle ml-50">Archive</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCourses"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import CourseListFilters from './CourseListFilters.vue';
import useCoursesList from './useCoursesList';
import rosterStoreModule from '../rosterStoreModule';
import CourseListAddNew from './CourseListAddNew.vue';
import moment from 'moment';

export default {
  components: {
    CourseListFilters,
    CourseListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    classesToRange(classes) {
      if (!classes || classes.length === 0) return 'No classes assigned';
      let dates = classes.sort((a, b) => a - b);

      return [
        moment(dates[0]).utc().format('MMM D'),
        moment(dates[dates.length - 1])
          .utc()
          .format('MMM D'),
      ].join(' - ');
    },
    archiveCourse(courseId) {
      store
        .dispatch('app-roster/updateCourse', {
          courseId,
          courseData: { status: 'archived' },
        })
        .then((response) => {
          this.refetchData();
        });
    },
  },
  setup() {
    const COURSE_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME))
      store.registerModule(COURSE_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME);
    });

    const isAddNewCourseSidebarActive = ref(false);

    const visibilityOptions = [
      { label: 'Internal', value: 'Public' },
      { label: 'Public', value: 'Public' },
    ];

    const {
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalCourses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,

      statusOptions,

      // UI
      resolveUserRoleVariant,
      resolveCourseVisibilityVariant,
      resolveCourseStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    } = useCoursesList();

    return {
      // Sidebar
      isAddNewCourseSidebarActive,

      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalCourses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,

      moment,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveCourseVisibilityVariant,
      resolveCourseStatusVariant,

      visibilityOptions,
      statusOptions,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    };
  },
  metaInfo() {
    return {
      title: `Courses`,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
